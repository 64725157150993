import IGCache from "../cache/IGCache";
import { propertiesInstagram } from "../properties";
import IGUser from "../model/instagram/IGUser";
import axios from 'axios';
import Media from "../model/instagram/Media";
import Color from "../model/instagram/Color";

// ### COLOR CLASSIFICATION ###
const COLOR_WHEEL = {
    white: '#ffffff',
    black: '#000000',
    grey: '#525252',
    red: '#ee1c25',
    // red_violet: '#b72468',
    violet: '#662c90',
    // violet_blue: '#534fa3',
    blue: '#0076b3',
    // blue_green: '#6dc8bf',
    // green: '#01a54f',
    green: '#22b52f',
    // green_yellow: '#add036',
    yellow: '#fef200',
    // yellow_orange: '#fdb813',
    orange: '#f78b1f',
    // orange_red: '#f15a23',
};

const nearest = require('nearest-color').from(COLOR_WHEEL);
const MAX_PALETTE = 5;

// ### VARS ###
const mediaColorList: Media[] = []; // list with all media associated with colors returned from Kala API
const COLORS_CLASSIFIED: any = {
    white: [],
    black: [],
    grey: [],
    red: [],
    // red_violet: [],
    violet: [],
    // violet_blue: [],
    blue: [],
    // blue_green: [],
    green: [],
    // green_yellow: [],
    yellow: [],
    // yellow_orange: [],
    orange: [],
    // orange_red: [],
};
const dominantGroups: any[] = [] // list with final 

// ### FUNCTIONS ###
export async function processImages() {
    const user: IGUser = IGCache.getInstance().user;
    const mediaJSON = user.toJsonTemplate();

    await checkAPIOn();

    const res = await axios.post(
        propertiesInstagram.KALA_API_URL,
        mediaJSON,
    ).catch(function () {
        throw new Error("An error occurred during image processing.");
    });

    const data = res.data;

    console.log("DATA OF API")
    console.log(data);

    const mediaList = IGCache.getInstance().mediaList;

    data.forEach((img: any) => {
        const id = img["id"];
        const colors = formatColors(id, img["colors"]);

        const mediaObj = mediaList.find((img: Media) => (img.getMediaID() === id) && img);

        if (mediaObj) {
            mediaObj.setHexColors(colors);
            mediaColorList.push(mediaObj);
        }
    });

    console.log("####### MEDIA WITH COLORS ########");
    console.log(mediaColorList);

    // Classify colors
    extractDominantPalette();

    console.log("### COLORS_CLASSIFIED ###");
    console.log(COLORS_CLASSIFIED);

    // Select top 5 most-repeated groups
    selectMostDominantColors();

    console.log("### DOMINANT GROUPS ###");
    console.log(dominantGroups);

    // Put in Cache 5 random-picked colors from dominant groups
    const colorList = pickRandomFromGroups();
    IGCache.getInstance().dominantPalette = colorList.slice();

    console.log("### CACHE ###");
    console.log(IGCache.getInstance().dominantPalette);
}


async function checkAPIOn() {
    await axios.get(propertiesInstagram.KALA_API_URL_CHECK)
        .catch(async function (error) {
            if (!error.status) { // not timeout: server error.
                await axios.get(propertiesInstagram.KALA_API_URL_CHECK);
            } else { // timeout
                throw new Error("Couldn't connect to KALA API.");
            }
        });
}


function formatColors(imgId: number, hexColors: any) {
    const colors: Color[] = [];
    hexColors.forEach((hex: any) => {
        colors.push(new Color(imgId, hex["hex"]));
    })
    return colors;
}


function pickRandomFromGroups() {
    const randomColors: Color[] = [];
    dominantGroups.forEach((group: any) => {
        const colorList: any = COLORS_CLASSIFIED[group];
        const random: Color = colorList[Math.floor(Math.random() * colorList.length)];

        if (random && !randomColors.find((element) => element.getHexColor() === random.getHexColor())) {
            randomColors.push(random);
        }

    });

    let limit = 100;
    while (randomColors.length < MAX_PALETTE && limit > 0) {
        limit--;
        const rnGroup = dominantGroups[Math.floor(Math.random() * dominantGroups.length)];
        const colorList = COLORS_CLASSIFIED[rnGroup];
        const rnColor: Color = colorList[Math.floor(Math.random() * colorList.length)];

        console.log(rnColor)

        if (rnColor !== undefined) {
            if (!randomColors.find((c) => c.getHexColor() === rnColor.getHexColor())) {
                randomColors.push(rnColor);
            }
        }

    }

    return randomColors;
}


function selectMostDominantColors() {
    const colorsCopy: any = {}
    Object.assign(colorsCopy, COLORS_CLASSIFIED);

    delete colorsCopy.white;
    delete colorsCopy.black;
    delete colorsCopy.grey;

    let i = 0;
    while (i < MAX_PALETTE) {
        const most = Object.keys(colorsCopy).reduce((a, b) => colorsCopy[a].length > colorsCopy[b].length ? a : b);
        if (colorsCopy[most].length > 0) {
            dominantGroups.push(most);
        }
        delete colorsCopy[most];
        i++;
    }
}


function extractDominantPalette() {

    mediaColorList.forEach((media: Media) => {
        media.getHexColors().forEach((color: Color) => {
            const colorGroup = nearest(color.getHexColor());
            const nameGroup = colorGroup.name;

            updateGroupsCount(color, nameGroup);
        });
    });
}

function updateGroupsCount(color: Color, nameGroup: any) {
    COLORS_CLASSIFIED[nameGroup].push(color);
}