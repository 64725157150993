<template>
<b-container fluid id="divisor">
    
    <Bubble id="bubble1"></Bubble>   
    <Bubble2 id="bubble2"></Bubble2>
    <Bubble3 id="bubble3"></Bubble3>
    <Bubble4 id="bubble5"></Bubble4>

    <h1 id="title">Your privacy is important</h1>
    <p>We are fully transparent.</p>
    <p>Because of that, we can be totally honest with you.</p>
    <b-row>
        <b-col align-self="center" lg="12" md="12">
            <b-button class="b-button" id="knowmore" @click="knowmore" pill variant="outline-secondary">Know more</b-button>
        </b-col>
    </b-row>
    <SolidLogo id="logoSolid"></SolidLogo>
 

</b-container>
</template>

<script lang="js">
import SolidLogo from "../../assets/solid_logo.vue";
import Bubble from "../../assets/bubble1.vue";
import Bubble2 from "../../assets/bubble2.vue";
import Bubble3 from "../../assets/bubble3.vue";
import Bubble4 from "../../assets/bubble4.vue";

export default {
    name: "SolidInfo",
    components: {
        SolidLogo,
        Bubble,
        Bubble2,
        Bubble3,
        Bubble4
       
    },
    methods: {
        knowmore: function () {
            window.open("https://inrupt.com/solid");
        },
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
#bubble1 {transform: translate(35vw, 5vw)rotate(270deg);}
#bubble2 {transform: translate(-55vw, -17vw)rotate(0deg);}
#bubble3 {transform: translate(28vw, 20vw)rotate(210deg);}
#bubble4 {transform: translate(10vw, -17vw)rotate(30deg);}
#bubble5 {transform: translate(26vw, 2vw)rotate(30deg);}

#divisor {
    background-color: #372cc7;
    background-image: url("../../assets/back_solid.png");
    background-position: center;
    background-size: cover;
    height: 100vh;
    margin-top: -2.9%;
    padding-top: 8%;
    z-index: -9999999999;
}

#title {
    font-size: 4vh;
    margin: 0% auto 3% auto;
    text-align: center;
    color: white;
    width: 80%;
}

#logoSolid {
    margin-top: auto;
    position: relative;
    transform: translate(0%, 850%);
}

#knowmore {
    background-color: white;
    margin-top: 1%;
    color: black;
    font-size: 2vh;
    font-weight: 600;
    padding: 0.5% 5% 0.5% 5%;
    flex-grow: 1;
}

p {
    color: white;
    font-size: 2vh;
    line-height: 80%;
}
</style>
