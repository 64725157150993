export const routesProperties = {
  welcome: "/",
  home: "/home",
  faq: "/faq",
  profile: "/profile",
  register: "/register",
  // registerSuccess: "/register/success",
  wordcloud: "/wordcloud",
  colorPalette: "/colorpalette",
  error: "/404",
  load: "/load",
  export: "/export",
}

export const propertiesInstagram = {
  appID: 850778308797588,
  appSecret: "d25e455dc94f727325c2c5ab5efcce78",
  redirectUriPalette: "https://kala.empathy.co/load/",
  redirectUriExport: "https://kala.empathy.co/export/",
  // redirectUriPalette: "https://localhost:8080/load/",
  // redirectUriExport: "https://localhost:8080/export/",
  KALA_API_URL:
    "https://f83k4x6gva.execute-api.eu-west-1.amazonaws.com/dev/v1/img_color",
  KALA_API_URL_CHECK:
    "https://f83k4x6gva.execute-api.eu-west-1.amazonaws.com/dev/test",
  // KALA_API_URL: 'http://127.0.0.1:5000/v1/img_color',
};

export const instagramUrls = {
  TOKEN_AUTH_URL: `https://api.instagram.com/oauth/access_token`,
  CODE_AUTH_URL_PALETTE: `https://api.instagram.com/oauth/authorize?client_id=${propertiesInstagram.appID}&redirect_uri=${propertiesInstagram.redirectUriPalette}&scope=user_profile,user_media&response_type=code`,
  CODE_AUTH_URL_EXPORT: `https://api.instagram.com/oauth/authorize?client_id=${propertiesInstagram.appID}&redirect_uri=${propertiesInstagram.redirectUriExport}&scope=user_profile,user_media&response_type=code`,
}

export const propertiesSolid = {
  // redirectUriLogin: `https://localhost:8080/home`,
  // redirectUriRegister: `https://localhost:8080/`,
  redirectUriLogin: `https://kala.empathy.co/home`,
  redirectUriRegister: `https://kala.empathy.co/`,
};

export const solidProviders = {
  INRUPT: "https://inrupt.net",
};