import { render, staticRenderFns } from "./PersonalProfileTab.vue?vue&type=template&id=03c8c897&scoped=true&"
import script from "./PersonalProfileTab.vue?vue&type=script&lang=ts&"
export * from "./PersonalProfileTab.vue?vue&type=script&lang=ts&"
import style0 from "./PersonalProfileTab.vue?vue&type=style&index=0&id=03c8c897&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03c8c897",
  null
  
)

export default component.exports