import Media from "./Media";

class IGUser {

    private id: string;
    private name: string;
    private accountType: string;
    private mediaCount: number;
    private mediaList: Media[];

    constructor(id: string, name: string, accountType: string, mediaCount: number, mediaList: Media[] = []) {

        this.id = id;
        this.name = name;
        this.accountType = accountType;
        this.mediaCount = mediaCount;
        this.mediaList = mediaList;
    }

    getUserID() {
        return this.id;
    }

    getUsername() {
        return this.name
    }

    getAccountType() {
        return this.accountType;
    }

    getMediaCount() {
        return this.mediaCount;
    }

    setMediaList(media: Media[]) {
        this.mediaList = media;
    }

    /**
     * 
     * @returns A JSON template containing all the info about the images of the user.
     */
    toJsonTemplate() {
        const IMAGE_LIMIT = 25;

        const imgList = this.mediaList.slice(0, IMAGE_LIMIT);

        const mediaListJson: any = [];
        imgList.forEach((m) => { mediaListJson.push(m.toJson()) });

        return JSON.stringify(
            {
                "auth_token": 1234,
                "images": mediaListJson
            }
        )
    }

    /**
     * This method is used when exporting the IG user profile to the Solid Pod.
     * @returns A JSON template containing all the info about the images of the user.
     */
    toJsonTemplateExport() {

        const imgList = this.mediaList;

        const mediaListJson: any = [];
        imgList.forEach((m) => { mediaListJson.push(m.toJson()) });

        return JSON.stringify(
            {
                "@context": {
                    "@version": 1.1,
                    "id": {
                        "@id": "schema:id",
                        "@type": "xs:string",
                    },
                    "name": {
                        "@id": "schema:name",
                        "@type": "xs:string"
                    },
                    "account_type": {
                        "@id": "schema:name",
                        "@type": "xs:string"
                    },
                    "media_count": {
                        "@id": "schema:number",
                        "@type": "xs:number"
                    },
                },
                "id": this.id,
                "name": this.name,
                "account_type": this.accountType,
                "media_count": this.mediaCount,
                "images": mediaListJson
            }
        )
    }
}

export default IGUser;