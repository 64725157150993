<template>
<div id="containerPalette">
    <h1 id="year">2021</h1>
    <h1 id="year">2021</h1>
    <h1 id="year">2021</h1>
    <b-img id="mainpic" rounded :src=items[0].mediaUrl></b-img>
    <b-img id="secpic" rounded="circle" :src=items[3].mediaUrl></b-img>
    <b-img id="secpic1" rounded :src=items[1].mediaUrl></b-img>
    <b-img id="secpic2" rounded :src=items[5].mediaUrl></b-img>
    <b-img id="mainpic1" rounded="circle" :src=items[2].mediaUrl></b-img>
    <b-img id="secpic3" rounded :src=items[4].mediaUrl></b-img>
    <b-img id="secpic4" rounded :src=items[6].mediaUrl></b-img>
    <h1 id="title">Your last year has been very <span class="color">colorful</span>, <br /> discover what mood you have! </h1>
    <div id="content"></div>
</div>
</template>

<script lang="js">
/* eslint-disable */
import * as d3 from "d3";
const margin = {
        top: 0,
        right: 0,
        bottom: 170,
        left: 250
    },
    width = window.innerWidth - margin.left - margin.right,
    height = 650 - margin.top - margin.bottom;
const circulos = [
    "50",
    "100",
    "70",
    "20",
    "35",
    "45",
    "80",
    "45",
    "20",
    "45",
    "35",
    "45",
    "80",

];

const data = [{
        value: 10,
        week: 1,
        month: "JAN",
    },
    {
        value: 2,
        week: 2,
        month: "JAN",
    },
    {
        value: 4,
        week: 3,
        month: "JAN",
    },
    {
        value: 8,
        week: 4,
        month: "JAN",
    },
    {
        value: 5,
        week: 1,
        month: "FEB",
    },
    {
        value: 2,
        week: 2,
        month: "FEB",
    },
    {
        value: 6,
        week: 3,
        month: "FEB",
    },
    {
        value: 8,
        week: 4,
        month: "FEB",
    },
    {
        value: 10,
        week: 1,
        month: "MAR",
    },
    {
        value: 1,
        week: 2,
        month: "MAR",
    },
    {
        value: 1,
        week: 3,
        month: "MAR",
    },
    {
        value: 6,
        week: 4,
        month: "MAR",
    },
    {
        value: 3,
        week: 1,
        month: "APR",
    },
    {
        value: 2,
        week: 2,
        month: "APR",
    },
    {
        value: 2,
        week: 3,
        month: "APR",
    },
    {
        value: 8,
        week: 4,
        month: "APR",
    },
    {
        value: 7,
        week: 1,
        month: "MAY",
    },
    {
        value: 2,
        week: 2,
        month: "MAY",
    },
    {
        value: 4,
        week: 3,
        month: "MAY",
    },
    {
        value: 8,
        week: 4,
        month: "MAY",
    },
    {
        value: 7,
        week: 1,
        month: "JUN",
    },
    {
        value: 2,
        week: 2,
        month: "JUN",
    },
    {
        value: 4,
        week: 3,
        month: "JUN",
    },
    {
        value: 8,
        week: 4,
        month: "JUN",
    },
    {
        value: 7,
        week: 1,
        month: "JUL",
    },
    {
        value: 2,
        week: 2,
        month: "JUL",
    },
    {
        value: 4,
        week: 3,
        month: "JUL",
    },
    {
        value: 8,
        week: 4,
        month: "JUL",
    },
    {
        value: 7,
        week: 1,
        month: "AUG",
    },
    {
        value: 2,
        week: 2,
        month: "AUG",
    },
    {
        value: 4,
        week: 3,
        month: "AUG",
    },
    {
        value: 8,
        week: 4,
        month: "AUG",
    },
    {
        value: 7,
        week: 1,
        month: "SEP",
    },
    {
        value: 2,
        week: 2,
        month: "SEP",
    },
    {
        value: 4,
        week: 3,
        month: "SEP",
    },
    {
        value: 8,
        week: 4,
        month: "SEP",
    },
    {
        value: 7,
        week: 1,
        month: "OCT",
    },
    {
        value: 2,
        week: 2,
        month: "OCT",
    },
    {
        value: 4,
        week: 3,
        month: "OCT",
    },
    {
        value: 8,
        week: 4,
        month: "OCT",
    },
    {
        value: 7,
        week: 1,
        month: "NOV",
    },
    {
        value: 2,
        week: 2,
        month: "NOV",
    },
    {
        value: 4,
        week: 3,
        month: "NOV",
    },
    {
        value: 8,
        week: 4,
        month: "NOV",
    },
    {
        value: 7,
        week: 1,
        month: "DEC",
    },
    {
        value: 2,
        week: 2,
        month: "DEC",
    },
    {
        value: 4,
        week: 3,
        month: "DEC",
    },
    {
        value: 8,
        week: 4,
        month: "DEC",
    },

];
export default {
    name: "DensityGraph",
    props: ['items'],
    mounted() {
        const imgtoprint = this.items;
        const svg = d3
            .select("#content")
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .attr("preserveAspectRatio", "xMinYMid meet")
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // X axis
        const x = d3
            .scaleBand()
            .range([0, width - 250])
            .domain(
                data.map(function (d) {
                    return d.month;
                })
            ).padding(0)

        svg
            .append("defs")
            .append("filter")
            .attr("id", "blur")
            .append("feGaussianBlur")
            .attr("stdDeviation", 2);

        svg
            .append("g")
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x))
            .selectAll("text")
            .attr("transform", "translate(-5,0)")
            .style("text-anchor", "center")
            .style("font-family", "Montserrat");

        const circles = svg.selectAll("circle").data(circulos).enter();

        circles
            .insert("circle")
            .attr("class", "empty")
            .attr("cx", (d, i) => i * 120)
            .attr("cy", 480)
            .attr("r", 10)
            .attr("fill", "none")
            .attr("stroke-width", 0.5)
            .style("opacity", 0)
            .attr("stroke", (d, i) => "#3E31DD");

        // Add Y axis
        const y = d3
            .scaleLinear()
            .domain([0, d3.max(data, (d) => d.value + 25)])
            .range([height, 0]);

        const ticks = svg.selectAll(".tick");
        ticks.selectAll("line").attr("stroke-width", 0);
        svg.selectAll("path").attr("stroke-width", 0);

        svg.append("line")
            .style("stroke", "#3E31DD")
            .attr("x1", 0)
            .attr("y1", 480)
            .attr("x2", 3000)
            .attr("y2", 480);

        // Bars
        const enterSelection = svg.selectAll("bar").data(data).enter();
        const tamanio = width - 250;
        enterSelection
            .insert("rect")
            .attr("class", "bar")
            .attr("x", function (d, i) {
                return i * (tamanio / 48);
            })
            .attr("width", 2)
            .attr("fill", "#3E31DD")
            // no bar at the beginning thus:
            .attr("height", function (d) {
                return height - y(0);
            }) // always equal to 0
            .attr("y", function (d) {
                return y(0);
            });
        // Animation
        svg
            .selectAll(".bar")
            .transition()
            .duration(1000)
            .attr("y", function (d) {
                return y(d.value);
            })
            .attr("height", function (d) {
                return height - y(d.value);
            })
            .delay(function (d, i) {
                return i * 300;

            });
        svg
            .selectAll(".empty")
            .attr("r", (d, i) => Math.random() * (150 - 50) + 10)
            .transition()
            .duration(2200)
            .style("opacity", 1)
            .delay(function (d, i) {
                return i * 1000;
            })
            .transition()
            .duration(6000)
            .attr("r", (d, i) => Math.random() * (500 - 30) + 100)
            .style("opacity", 0)
            .delay(function (d, i) {
                return i * 500;
            })

        //imagenes

    }

}
</script>

<style scoped>
#content {
    position: absolute;
    top: 30%;
    left: 0px;
    height: 150vh;
}

#title {
    position: absolute;
    top: 50%;
    left: 30%;
    font-weight: 600;
    z-index: 99999999;
    letter-spacing: 2px;
    font-size: 2vw;
    color: darkgrey;
}

svg img {
    border-radius: 50%;

}

#mainpic {
    position: absolute;
    right: 16vh;
    object-fit: cover;
    top: 55vh;
    width: 10vw;
    height:10vw;
    display: flex;
    float: right;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    opacity: 0.54;

}

#mainpic1 {
    position: absolute;
    object-fit: cover;
    right: 55vh;
    top: 20vh;
    width: 13vw;
    height: 13vw;
    display: flex;
    float: right;
    opacity: 0.8;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);

}

#secpic {
    position: absolute;
    object-fit: cover;
    left: 25vh;
    top: 35vh;
    width: 8vw;
    height: 8vw;
    display: flex;
    float: left;
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
}

#secpic1 {
    position: absolute;
    object-fit: cover;
    left: 45vh;
    top: 45vh;
    width: 5vw;
    height: 5vw;
    display: flex;
    float: left;
    opacity: 0.5;
    filter: blur(2px);
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);

}

#secpic2 {
    position: absolute;
    object-fit: cover;
    left: 55vh;
    top: 20vh;
    width: 3vw;
    height: 3vw;
    display: flex;
    float: left;
    opacity: 0.8;
    filter: blur(2px);
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);

}

#secpic3 {
    position: absolute;
    object-fit: cover;
    left: 70vh;
    top: 50vh;
    width: 10vw;
    height: 10vw;
    display: flex;
    float: left;
    opacity: 0.2;
    filter: blur(2px);
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);

}

#secpic4 {
    position: absolute;
    object-fit: cover;
    left: 90vh;
    top: 20vh;
    width: 6vw;
    height: 6vw;
    display: flex;
    float: left;
    opacity: 0.9;
    filter: blur(1px);
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);

}

#year {
    margin-left: 70vw;
    font-size: 15vh;
    font-weight: bolder;
    margin-top: -4%;

    color: #FFFFFF;
    text-shadow: -1px -1px 0 #6E65E6, 1px -1px 0 #6E65E6, -1px 1px 0 #6E65E6, 1px 1px 0 #6E65E6;
}

.color {
    background-image: -webkit-gradient(linear, 0% 20%, 0% 100%, from(rgb(36, 216, 207)), to(rgb(32, 60, 189)));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>
