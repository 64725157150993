


























































import * as d3 from "d3";
import Bubble2 from "../../assets/bubble2.vue";
import Bubble4 from "../../assets/bubble4.vue";
import Bubble3 from "../../assets/bubble3.vue";
export default {
    name: "ColorPaletteExample",
    components: {
        Bubble2,
        Bubble3,
        Bubble4
    },
    mounted() {
        const data = [25, 20, 10, 12, 15];
        const colors = ["#CD9C61", "#2288AE", "#351C4E", "#2288AE", "#CD9C61"];
        const svg = d3
            .select("#chart-area")
            .append("svg")
            .attr("width", "50%")
            .attr("height", "100%")
            .attr("preserveAspectRatio", "xMinYMid meet")
            .attr("viewBox", `0 0 100 20`);
        // Define the gradient
        const gradient = svg
            .append("defs")
            .selectAll("radialGradient")
            .data(colors)
            .enter()
            .append("radialGradient")
            .attr("gradientUnits", "objectBoundingBox")
            .attr("cx", 0)
            .attr("cy", 0)
            .attr("r", "100%")
            .attr("id", function (d, i) {
                return "grad" + i;
            });
        // Define the gradient colors
        gradient
            .append("svg:stop")
            .attr("offset", "0%")
            .attr("stop-color", "white")
            .attr("stop-opacity", 1);
        gradient
            .append("svg:stop")
            .attr("offset", "100%")
            .attr("stop-color", function (d, i) {
                return colors[i];
            })
            .attr("stop-opacity", 1);
        const circles = svg.selectAll("circle").data(data);
        circles
            .enter()
            .append("circle")
            .attr("r", "0.30vw")
            .attr("cx", (d, i) => i * 18 + 15)
            .attr("cy", "50%")
            .style("fill", function (d, i) {
                return "url(#grad" + i + ")";
            });
    },
};
