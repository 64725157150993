/*eslint no-case-declarations: "off"*/
import IGCache from "../cache/IGCache";
import { instagramUrls, propertiesInstagram } from "../properties";
import axios from 'axios';
import Media from "../model/instagram/Media";
import IGUser from "../model/instagram/IGUser";

const querystring = require("querystring");
const JSONbig = require('json-bigint');

// VARS
let authToken: any = null;
let userID: any = null;
let carouselID: any = null; // id of the carousel

// #########
// FUNCTIONS
// #########
export function launchAuthWindow(url: string) {
  window.location.assign(url);
}

export async function getAuthToken(urlStr: string, redirectUrl: string) {
  const urlParams = urlStr.split("?");
  const authCode = urlParams[1].replace("code=", "").replace("#_", "");

  console.log(redirectUrl)

  const res = await axios.post(
    instagramUrls.TOKEN_AUTH_URL,
    querystring.stringify({
      client_id: propertiesInstagram.appID,
      client_secret: propertiesInstagram.appSecret,
      grant_type: "authorization_code",
      redirect_uri: redirectUrl,
      code: authCode,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      transformResponse: (data: any) => JSONbig.parse(data),
    }
  ).catch(function () {
    throw new Error("An error occurred during Instagram login process.");
  });

  const data = res.data;

  authToken = data.access_token;
  // userID = String(data.user_id);
  userID = (data.user_id);
  console.log(userID);
}

export async function getAllUserData() {
  const user = await getUserData();
  const mediaList = await getMediaData();
  user.setMediaList(mediaList);
  IGCache.getInstance().setUserToCache(user);

  processHashtags();

  console.log("###### FINAL USER ######");
  console.log(IGCache.getInstance().user);
  console.log(IGCache.getInstance().mediaList);
}

function processHashtags() {
  const hashtags = IGCache.getInstance().mediaList;

  let captions: any = hashtags.map(function (e: Media) {
    if (e.getCaption() !== undefined) {
      const words = e.getCaption().split(" ");
      return words;
    }
    return null;
  });
  captions = captions
    .join(",")
    .split(",")
    .filter((word: string) => word !== undefined && word.startsWith("#"));

  const x = (captions: any) => captions.filter((v: string, i: number) => captions.indexOf(v) === i);
  const all = x(captions);

  const finalWords = all.slice(0, 15).map(function (e: string, i: number) {
    return { text: e, weight: Math.floor(Math.random() * 100 + 80) * (i + 1) };
  });

  IGCache.getInstance().hashtags = finalWords;
  const words = [
    "#vibrant",
    "#creative",
    "#energetic",
    "#bohemian",
    "#freeSpirit",
    "#wild",
    "#natural",
    "#joyful",
    "#playful",
    "#zen",
    "#adventurous",
  ];
  IGCache.getInstance().mood = words[Math.floor(Math.random() * words.length)];
}

async function getUserData() {

  const res = await axios.get(
    `https://graph.instagram.com/${userID}/?fields=id,username,account_type,media_count&access_token=${authToken}`
  );

  const data = res.data;
  const user: IGUser = new IGUser(
    data.id,
    data.username,
    data.account_type,
    data.media_count
  );

  return user;
}

async function getMediaData() {
  const res = await axios.get(
    `https://graph.instagram.com/${userID}/media?fields=id,caption,media_type,media_url,timestamp&access_token=${authToken}&limit=100`
  ).catch(function () {
    throw new Error("An error occurred during Instagram user's media fetch process.");
  });

  const data = res.data;

  const mediaData = data.data;
  // let mediaCursorsNext = data.paging.next;

  const mediaList = [];

  for (const m of mediaData) {
    switch (m.media_type) {
      case "IMAGE":
        const mediaObj = new Media(
          m.id,
          m.caption,
          m.media_type,
          m.media_url,
          m.timestamp,
          ".jpg"
        );
        mediaList.push(mediaObj);
        IGCache.getInstance().addMediaToCache(mediaObj);

        break;
      case "CAROUSEL_ALBUM":
        carouselID = m.id;
        const resCarousel = await axios.get(
          `https://graph.instagram.com/${carouselID}/children?fields=id,media_url,timestamp&access_token=${authToken}`
        );
        const dataCarousel = resCarousel.data.data;
        dataCarousel.forEach((e: any) => {
          if (!e.media_url.includes("video")) {
            const mediaObj = new Media(
              e.id,
              m.caption,
              m.media_type,
              e.media_url,
              e.timestamp,
              ".jpg"
            );
            mediaList.push(mediaObj);
            IGCache.getInstance().addMediaToCache(mediaObj);
          }
        });
        break;
      case "VIDEO":
        // do nothing
        break;
      default:
        throw new Error();
    }
  }

  return mediaList;
}

export async function getUserDataToExport() {
  const user = await getUserData();
  const mediaList = await getMediaData();
  user.setMediaList(mediaList);
  IGCache.getInstance().setUserToCache(user);
}
