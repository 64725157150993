<template>
<b-container v-if="!loading" fluid id="profileTabs">
    <ul class="nav nav-tabs nav-justified">
        <li class="nav-item" :class="{ active: isActive('profile'), noactive: !isActive('profile') }">
            <a class="nav-link" @click.prevent="setActive('profile')" href="#profile">Profile</a>
        </li>
        <li class="nav-item" :class="{
          active: isActive('yourphotos'),
          noactive: !isActive('yourphotos'),
        }">
            <a class="nav-link" @click.prevent="setActive('yourphotos')" href="#yourphotos">Your photos</a>
        </li>
        <li class="nav-item" :class="{
          active: isActive('configuration'),
          noactive: !isActive('configuration'),
        }">
            <a class="nav-link" @click.prevent="setActive('configuration')" href="#configuration">Configuration</a>
        </li>
    </ul>
                   

    <div class="tab-content py-3" id="myTabContent">
        <div class="tab-pane fade" :class="{ 'active show': isActive('profile') }" id="profile">
            <PersonalProfileTab></PersonalProfileTab>

        </div>
        <div class="tab-pane fade" :class="{ 'active show': isActive('yourphotos') }" id="yourphotos">
            <YourPhotosTab v-bind:palette="palette"></YourPhotosTab>
        </div>
        <div class="tab-pane fade" :class="{ 'active show': isActive('configuration') }" id="configuration">
            <ConfigurationTab></ConfigurationTab>
        </div>
    </div>
</b-container>
<b-container v-else fluid id="profileTabs">
    <h1>Loading...</h1>
</b-container>
</template>

<script lang="js">
import PersonalProfileTab from "./PersonalProfileTab.vue"
import YourPhotosTab from "./YourPhotosTab.vue"
import ConfigurationTab from "./ConfigurationTab.vue"
import { saveSolidInfo } from "@/backend/handler/SessionHandler"
import { findAllColorPalettes } from "@/backend/handler/PodHandler"
import SolidCache from "@/backend/cache/SolidCache"

export default {
    name: "ProfileTabs",
    components: {
        PersonalProfileTab,
        YourPhotosTab,
        ConfigurationTab,
        
    },
    data() {
        return {
            activeItem: 'profile',
            loading: true,
            palette: null
        }
    },
    beforeMount() {
        saveSolidInfo().then(() => {
            const solidcache = SolidCache.getInstance();
            const webid = solidcache.getWebId().split("profile")[0];
            
            findAllColorPalettes(webid).then((p) => {
                if(p !== null) {
                    this.palette = p;
                }
                this.loading = false;
            });            
        });
    },
    methods: {
        isActive(menuItem) {
            return this.activeItem === menuItem;
        },
        setActive(menuItem) {
            this.activeItem = menuItem;
        }
    }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
#profileTabs {
    width: 60%;
    min-height: 400px;
    background-color: #efefef;
    position: relative;
}

.nav-item.active {
    background-color: #efefef !important;
    border-bottom: 2px solid #4538de;
}

.noactive {
    opacity: 0.6;
    border-bottom: 2px solid #e0def1;
}

.nav-link {
    color: #4538de;
    font-weight: bold;
}
</style>
