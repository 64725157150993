
























































import {
  initLogin,
  initLogout,
  initRegister,
  isAuthenticated,
} from "@/backend/handler/SessionHandler";
import Logo from "../../assets/kala_logo.vue";
import Avatar from "../../assets/avatar.vue";
import Arrow from "../../assets/arrow_down.vue";
import { routesProperties } from "../../../backend/properties";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    Logo,
    Avatar,
    Arrow,
  },
})
export default class Header extends Vue {
  isAuth = false;

  login() {
    initLogin();
  }

  register() {
    initRegister();
  }

  goWelcome() {
    window.location.href = routesProperties.welcome;
  }

  goHome() {
    window.location.href = routesProperties.home;
  }

  logout() {
    initLogout();
  }

  account() {
    window.location.href = routesProperties.profile;
  }

  beforeMount() {
    isAuthenticated().then((auth) => {
      if (auth) {
        this.isAuth = true;
      } else {
        this.isAuth = false;
      }
    });
  }
}
