<template>
<div class="WelcomePage">
    <Header />
    <Bubble3 id="bubble1"></Bubble3>
    <div id="mainWelcome">
        <WelcomeScroll id="face" />
        <b-container fluid id="containerWelcome">
            <b-row>
                <b-col align-self="center">
                    <h1 id="title">COLOUR YOUR EMOTIONS!</h1>
                </b-col>
            </b-row>
            <MouseScroll id="mouseScroll"></MouseScroll>
        </b-container>
    </div>
    <PrivacyInfo />
    <Example />
    <SolidInfo />
    <FAQListWelcome id="list"></FAQListWelcome>
    <Footer />
</div>
</template>

<script lang="js">
import Header from "../components/headers/Header.vue";
import Footer from "../components/Footer.vue";
import SolidInfo from "../components/welcome/SolidInfo.vue";
import Example from "../components/outputs/ColorPaletteExample.vue";

import FAQListWelcome from "../components/faq/FAQListWelcome.vue";
import PrivacyInfo from "../components/welcome/PrivacyInfo.vue";
import WelcomeScroll from "../components/welcome/WelcomeScroll.vue";
import MouseScroll from "../assets/mouse_scroll.vue";
import Bubble3 from "../assets/bubble3.vue";

export default {
    name: "WelcomePage",

    components: {
        Header,
        WelcomeScroll,
        Footer,
        FAQListWelcome,
        SolidInfo,
        PrivacyInfo,
        MouseScroll,
        Example,
        Bubble3
    },

    mounted() {
        // isAuthenticated().then((r) => {
        //   this.auth = r;
        // })
    }
}
</script>

<style scoped>
.WelcomePage {
    background-color: #e8e8e8;
    position: relative;
    overflow-x: hidden;

}

#bubble1 {
    transform: translate(-50vw, 5vw)rotate(30deg);
}

#mainWelcome {
    width: 150vw;
    height: 45vw;
    position: relative;

}

#mouseScroll {
    top: -35vw;
    left: -25vw;
}

#list {
    background-color: #3c2fdd;
    padding-bottom: 50vh;
}

#face {
    margin: auto;
    background-color: transparent;
    width: 49.3vw;

    transform: translate(-25vw, -10vw) rotate(90deg);
}

#title {
    font-size: 5vw;
    font-weight: bold;
    margin: auto;
    text-align: center;
    color: white;
    width: 30%;
    letter-spacing: 5px;
    position: relative;
    transform: translate(-25vw, -40vw);
}
</style>
