
class SolidUser {

    readonly name?: string;
    readonly photoUrl?: string;
    readonly email?: string;

    constructor(name?: string, photoUrl?: string, email?: string) {
        this.name = name;
        this.photoUrl = photoUrl;
        this.email = email;
    }
}

export default SolidUser;