import { getSolidDataset, getStringNoLocale, getThing, getUrl, ThingPersisted } from '@inrupt/solid-client';
import { fetch } from '@inrupt/solid-client-authn-browser'
import { VCARD } from '@inrupt/vocab-common-rdf';
import SolidCache from '../cache/SolidCache';
import SolidUser from '../model/solid/SolidUser';
import { propertiesSolid, routesProperties, solidProviders } from '../properties';

const auth = require('solid-auth-client');

/**
 * This function handles the Solid login process.
 * 
 * Then checks if the user is already logged in (precondition), and in case it's not
 * it triggers the login process.
 * 
 */
export async function initLogin() {

    const session = await auth.currentSession();
    if (!session) {
        await auth.login(solidProviders.INRUPT, {
            callbackUri: propertiesSolid.redirectUriLogin
        });
    } else {
        window.location.href = routesProperties.home;
    }

    saveSolidInfo();
}

/**
 * This function handles the logout process.
 * 
 * It does not redirect the user away. Just logs the user out of
 * the application.
 */
export async function initLogout() {
    if (auth.currentSession()) {
        await auth.logout();
        window.location.href = routesProperties.welcome;
    }
}

/**
 * This function handles the register process.
 * 
 * The redirect option is handled by the URL parameter "returnToUrl"
 */
export async function initRegister() {

    const registerURL = `${solidProviders.INRUPT}/register?returnToUrl=${propertiesSolid.redirectUriRegister}`

    const session = await auth.currentSession();
    if (!session) {
        console.log("ss")
        window.location.href = registerURL;
    }
}

/**
 * This function retrieves all the personal info of the user from its Solid profile.
 * The data to be collected is: 
 *  - Username
 *  - Photo 
 *  - WebId
 * 
 * Wehen retrieved, that info is stored in the SolidCache as a new SolidUser.
 */
export async function saveSolidInfo() {

    const webId = await loadWebId();
    SolidCache.getInstance().setWebId(webId);

    if (webId) {
        const myDataset = await getSolidDataset(webId, { fetch: fetch });
        const profile = getThing(myDataset, webId);

        if (profile) {
            const userName = loadUserName(profile);
            const email = loadUserEmail(profile);
            const userPhoto = loadUserPhoto(profile);

            const user = new SolidUser((userName === null || userName === "" ? undefined : userName), (userPhoto === null ? undefined : userPhoto), (email === null ? undefined : email));

            SolidCache.getInstance().setUserToCache(user);
        }
    } else {
        throw new Error("WebID value is undefined");
    }

}

/**
 * This function retrieves the webId of the logged in user.
 * 
 * @returns string - The webId of the logged user.
 */
async function loadWebId() {
    const session = await auth.currentSession();
    if (session) {
        return session.webId;
    } else {
        throw new Error('No session found');
    }
}

// ---------------- LOAD FUNCTIONS ---------------- 
function loadUserName(profile: ThingPersisted) {
    return getStringNoLocale(profile, VCARD.fn);
}

function loadUserEmail(profile: ThingPersisted) {
    return getStringNoLocale(profile, VCARD.hasEmail)
}

function loadUserPhoto(profile: ThingPersisted) {
    return getUrl(profile, VCARD.hasPhoto)
}
//-------------------------------------------------

/**
 * This function checks whether the user is authenticated or not.
 * 
 * @returns boolean - True if the user is authenticated. False otherwise. 
 */
export async function isAuthenticated() {
    const session = await auth.currentSession();
    if (session) {
        return true;
    } else {
        return false;
    }
}

// export async function checkSession() {
//     const session = await auth.currentSession();
//     if (session) {
//         await saveSolidInfo();
//     } else {
//         window.location.href = routesProperties.error;
//     }
// }