






































import Bubble1 from "../../assets/bubbleProfilePic.vue";
import Bubble4 from "../../assets/bubble4.vue";
import Bubble2 from "../../assets/bubble2.vue";
import SolidCache from "@/backend/cache/SolidCache";
import {
    saveSolidInfo
} from "@/backend/handler/SessionHandler";
import {
    Component,
    Vue
} from "vue-property-decorator";
import { findAllColorPalettes } from "@/backend/handler/PodHandler";
@Component({
    components: {
        Bubble1,
        Bubble4,
        Bubble2
    },
})
export default class PersonalProfileTab extends Vue {

    // loading = true;
    photoUrl: string | undefined = "";
    username: string | undefined = "";
    webId: string | undefined = "";


    mounted() {
        // saveSolidInfo().then(() => {
            this.getUserInfo();

            // this.loading = false;
        // });
    }
    getUserInfo() {
        const cache = SolidCache.getInstance();
        this.username = cache.getUser().name;
        this.photoUrl = cache.getUser().photoUrl;
        this.webId = this.formatWebId(cache.getWebId());
    }
    formatWebId(webid: string | undefined) {
        let formatedWebId;
        if (webid) {
            formatedWebId = webid
                .replace("https://", "")
                .replace(/.inrupt.net.*/, "");
        }
        return formatedWebId;
    }

    goToSolid() {
        window.open(SolidCache.getInstance().getWebId(), "_blank");
    }
}

