import IGCache from "../cache/IGCache";
import SolidCache from "../cache/SolidCache";
import { propertiesInstagram } from "../properties";
import { processImages } from "./APIHandler";
import { getAuthToken, launchAuthWindow, getAllUserData, getUserDataToExport } from "./IGHandler";
import { exportIGData } from "./PodHandler";

/**
 * This function initializes the Auth process of Instagram.
 * It launches the required window at Instagram for the authorization
 * of the user.
 * 
 * This function must be called ONLY before executeMain() 
 * and the call must be done from the HomePage. 
 */
export function initAuth(url: string) {
    launchAuthWindow(url);
}

/**
 * This function executes the KALA process:
 * 
 * 1 - Calls the IGHandler in order to get the user's data and media.
 * 2 - Calls the APIHandler in order to process the images.
 * 
 * @param url - The exact url of the page from where it's called. The url should include the "code" param. 
 */
export async function executeMain(url: string, ) {
    await getAuthToken(url, propertiesInstagram.redirectUriPalette);
    await getAllUserData();
    await processImages();
}

export async function exportIGToPod(url: string) {
    await getAuthToken(url, propertiesInstagram.redirectUriExport);
    await getUserDataToExport();
    const igcache = IGCache.getInstance();
    const solidcache = SolidCache.getInstance();

    const webid = solidcache.getWebId(); 
    if(webid) {
        console.log(igcache);
        exportIGData(igcache.user.getUserID(), igcache.user.getUsername(), webid.split("profile")[0]);
    } else {
        throw new Error("Solid WebId can't be null or undefined.");
    }

}
