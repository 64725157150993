




























import { Component, Vue } from "vue-property-decorator";
import Bubble1 from "../assets/bubble1.vue";
import Bubble2 from "../assets/bubble2.vue";
import Header from "../components/headers/Header.vue";
import Footer from "../components/Footer.vue";
import {routesProperties} from "../../backend/properties";

@Component({
   components: {
        Header,
        Footer,
        Bubble1,
        Bubble2,
        
    },
  data() {
    return {
      link: "",
    };
  },   
  beforeMount() {
    const param = this.$route.query.err;
    switch (param) {
      case "not-authenticated":
        this.$data.link = "/";
        break;
      case "not-found":
        this.$data.link = "/";
        break;
      default:
        this.$data.link = "home";
        break;
    }
  },
})
export default class ErrorPage extends Vue {

   execute() {
    window.location.href = routesProperties.welcome;
  }
}
