
























import {
    Component,
    Vue
} from "vue-property-decorator";
import Header from "../components/headers/Header.vue";
import Footer from "../components/Footer.vue";
import Bubble1 from "../assets/bubble1.vue";
import Bubble2 from "../assets/bubble2.vue";
import Bubble3 from "../assets/bubble3.vue";
import Bubble4 from "../assets/bubble4.vue";
import SolidCache from "@/backend/cache/SolidCache";
import {
    saveSolidInfo
} from "@/backend/handler/SessionHandler";
import {
    initAuth
} from "@/backend/handler/MainHandler";
import {
    instagramUrls
} from "@/backend/properties";

@Component({
    components: {
        Header,
        Footer,
        Bubble1,
        Bubble2,
        Bubble3,
        Bubble4
    },
})
export default class HomePage extends Vue {

    loading = true;
    photoUrl: string | undefined = "";
    background: string | undefined = "";
    username: string | undefined = "";
    webId: string | undefined = "";
    mounted() {
        saveSolidInfo().then(() => {
            this.getUserInfo();
            this.loading = false;
        });
    }
    getUserInfo() {
        const cache = SolidCache.getInstance();
        console.log(cache);
        this.username = cache.getUser().name;
        this.photoUrl = cache.getUser().photoUrl;
        if (this.photoUrl != undefined) {
            this.background = "url(" + cache.getUser().photoUrl + ")";
        } else {
            this.background = "url('/avatar.png')";
        }
        this.webId = this.formatWebId(cache.getWebId());
    }
    formatWebId(webid: string | undefined) {
        let formatedWebId;
        if (webid) {
            formatedWebId = webid
                .replace("https://", "")
                .replace(/.inrupt.net.*/, "");
        }
        return formatedWebId;
    }
    execute() {
        initAuth(instagramUrls.CODE_AUTH_URL_PALETTE);
    }
}
