












































import Bubble2 from "../../assets/bubble2.vue";
import { Component, Vue } from "vue-property-decorator";
import { initAuth } from "@/backend/handler/MainHandler";
import { instagramUrls, routesProperties } from "@/backend/properties";

@Component({
  components: {
    Bubble2,
  },
  props: ['palette']
})
export default class YourPhotosTab extends Vue {

  goHome() {
    window.location.href = routesProperties.home;
  }

  execute() {
    initAuth(instagramUrls.CODE_AUTH_URL_EXPORT);
  }
}
