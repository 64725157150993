<template>
<div id="wordcloud">
    <h1>..and you are a real trend lover! <br /> These were your top hashtags</h1>
    <vue-word-cloud style="
      margin:auto;
    height: 50vh;
    width: 30vw;
  " :words=hashtags :color="
        ([, weight]) =>
           '#3E31DD' 
      " font-family="Montserrat" :spacing="2" />
</div>
</template>

<script lang="js">
import VueWordCloud from 'vuewordcloud';
export default {
    name: "WordCloud",
    components: {
        VueWordCloud
    },
    props: ['hashtags'],
};
</script>

<style scoped>
h1 {
    font-weight: 600;
    letter-spacing: 2px;
}
</style>
