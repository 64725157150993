/* eslint-disable */
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import WelcomePage from '../views/WelcomePage.vue'
import HomePage from '../views/HomePage.vue'
import ProfilePage from '../views/ProfilePage.vue'
import Register from '../components/Register.vue'
import LoginPage from '../views/LoginPage.vue'
import LoadPage from '../views/LoadPage.vue'
import LoadPageExport from '../views/LoadPageExport.vue'
import ErrorPage from '../views/ErrorPage.vue'
import OutputPage from '../views/OutputPage.vue'
import FAQPage from '../views/FAQPage.vue'
import { routesProperties } from '@/backend/properties'
import { isAuthenticated } from '@/backend/handler/SessionHandler'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: routesProperties.welcome,
    name: 'welcome',
    component: WelcomePage,
    meta: { requiresAuth: false },
  },
  {
    path: routesProperties.home,
    name: 'home',
    component: HomePage,
    meta: { requiresAuth: true },
  },
  {
    path: routesProperties.profile,
    name: 'profile',
    component: ProfilePage,
    meta: { requiresAuth: true },
  },
  // {
  //   path: '/output',
  //   name: 'OutputPage',
  //   component: OutputPage,
  //   meta: { requiresAuth: true },
  // },
  // {
 
  //   path: routesProperties.register,
  //   name: 'register',
  //   component: Register,
  //   meta: { requiresAuth: false },
  // }, 
  {
    path: routesProperties.faq,
    name: 'faq',
    component: FAQPage,
    meta: { requiresAuth: false },
  }, 
  // {
  //   path: routesProperties.registerSuccess,
  //   name: 'login',
  //   component: LoginPage,
  //   meta: { requiresAuth: false },
  // },
  {
    path: routesProperties.error,
    name: '404',
    component: ErrorPage,
    meta: { requiresAuth: false },
  },
  {
    path: routesProperties.load,
    name: 'load',
    component: LoadPage,
    meta: { requiresAuth: true },
  },
  {
    path: routesProperties.export,
    name: 'export',
    component: LoadPageExport,
    meta: {requiresAuth: true},
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if(to.name !== null) {
    if (to.meta.requiresAuth === true) {
      const auth = await isAuthenticated();
      if (!auth) {
        next({
          path: routesProperties.error,
          query: { err: 'not-authenticated' }
        });
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next({
      path: routesProperties.error,
      query: { err: 'not-found' }
    });
  }
});

// router.afterEach(async (to, from) => {
//   if (to.meta.requiresAuth === true) {
//     await saveSolidInfo();
//   }
// });

router.onError(() => {
  router.push('404');
});

export default router
