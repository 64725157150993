




















import { exportIGToPod } from "@/backend/handler/MainHandler";
import { Component, Vue } from "vue-property-decorator";
import Bubble1 from "../assets/bubble1.vue";
import Bubble2 from "../assets/bubble2.vue";
import Logo from "../assets/kala_logo.vue";
import OutputPage from "./OutputPage.vue";
import { routesProperties } from "../../backend/properties";
import { saveSolidInfo } from "@/backend/handler/SessionHandler";

@Component({
  components: {
    Bubble1,
    Logo,
    Bubble2,
    OutputPage,
  },
})
export default class LoadPageExport extends Vue {
  loading = true;

  mounted() {
    saveSolidInfo().then(() => {
      exportIGToPod(window.location.href).then(() => {
        this.$router.push({
          path: routesProperties.profile,
          query: { state: "export-success" },
        });
      });
    });
  }

  goHome() {
    window.location.href = routesProperties.welcome;
  }
}
