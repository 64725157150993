<template>
<div id="container">
    <Bubble3 id="bubble1"></Bubble3>
    <b-container fluid id="card">
        <b-row id="row">
            <h2>FAQ</h2>
        </b-row>
        <b-list-group id="questionsRow">
            <b-list-group-item id="cardItem" v-for="question in questions" v-bind:key="question.id">
                <div>
                    <h6>
                        <strong>{{ question.title }}</strong>
                    </h6>
                    <h5>
                        <strong>{{ question.subtitle }}</strong>
                    </h5>
                </div>
                <p>{{ question.msg }}</p>
            </b-list-group-item>
        </b-list-group>
    </b-container>
    <b-button class="b-button" id="knowmore" @click="knowmore" pill variant="outline-secondary">Know more</b-button>
</div>
</template>

<script lang="js">
import {
    routesProperties
} from '../../../backend/properties.ts';
import Bubble3 from "../../assets/bubble3.vue";

export default {
    name: "FAQListWelcome",
    components: {
        Bubble3,
    },
    methods: {
        knowmore: function () {
            window.location.href = routesProperties.faq;
        },
    },
    data() {
        return {
            questions: [{
                    title: "What is a Pod?",
                    subtitle: "",
                    msg: "",
                },
                {
                    // title: "What exactly is personal data?",
                    subtitle: "What exactly is personal data?",
                    msg: " Personal data is personal. It refers to data sets describing a person ranging from physical attributes to their preferences and behaviour. Personal data is a legal concept, not a technical concept, and it is not utilised in all jurisdictions.",
                },

                {
                    title: "Is data in my Pod safe?",
                    subtitle: "",
                    msg: "",
                },

            ],

        };
    },

};
</script>

<style scoped>
#container {
    background-color: #3c2fdd;
   
}
#bubble1 {transform: translate(-35vw, 9vw)rotate(210deg); }


#card {
    width: 70vw;
}

#row {
    text-align: left;
    height: 20vh;
}

p {
    font-size: 1.5vh;
}

#cardItem {
    width: 80%;
    margin: 0% 0% 2% 10%;
    background-color: transparent;
    border: 1px solid #9aa7ee;
    border-radius: 50px;
    padding: 3%;
    text-align: left;
    color: white;
    box-shadow: 10px 5px 5px #2b22b1;
}

#questionsRow #cardItem:nth-child(1) {
    opacity: 0.7;
    height: 10px;
}

#questionsRow #cardItem:nth-child(3) {
    opacity: 0.7;
    height: 10px;
}

#knowmore {
    background-color: white;
    color: black;
    font-size: 2vh;
    font-weight: 600;
    padding: 0.5% 5% 0.5% 5%;
    z-index: 10;
}

h6 {
    color: #605de3;
    line-height: 25px;
    font-size: 2vh;
}

h5 {
    font-size: 2.5vh;
}

h2 {
    line-height: 50px;
    margin: 5% 4% 5% 11%;
    color: white;
    font-size: 5vh;
}
</style>
