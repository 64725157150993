import IGCache from "../../cache/IGCache";
import Media from "./Media";


class Color {

    private mediaId: number;
    private hexColor: string;

    constructor(mediaId: number, hexColor: string) {
        this.mediaId = mediaId;
        this.hexColor = hexColor;
    }

    /**
     * Returns the id of the Media this Color is related to
     */
    getMediaId() {
        return this.mediaId;
    }

    getRelatedMediaFromCache() {
        return IGCache.getInstance().mediaList.find((m: Media) => m.getMediaID() === this.mediaId);
    }

    /**
     * Returns the Hex color including the symbol '#'
     */
    getHexColor() {
        return this.hexColor;
    }

    /**
     * Returns the Hex color deleting the symbol '#'
     */
    getColorCode() {
        return this.hexColor.replace("#", "");
    }

}

export default Color;