<template>
  <div id="slider">
    <VueCompareImage
      leftImage="../facemask.png"
      rightImage="../face.png"
      :sliderLineWidth="0"
      :handleSize="80"
    />
  </div>
</template>

<script lang="js">
import VueCompareImage from './VueCompareImage.vue';
export default {
  name: "WelcomeScroll",
  components: { VueCompareImage },
};
</script>

<style scoped></style>
