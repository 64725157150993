


























import KalaBird from "../assets/kala_bird.vue";
import Powered from "../assets/powered.vue";

export default {
    name: "Footer",
    components: {
        KalaBird,
        Powered
    },
};
