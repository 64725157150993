










































import {
    executeMain
} from "@/backend/handler/MainHandler";
import {
    Component,
    Vue
} from "vue-property-decorator";
import Bubble1 from "../assets/bubble1.vue";
import Bubble2 from "../assets/bubble2.vue";
import Logo from "../assets/kala_logo.vue";
import OutputPage from '../views/OutputPage.vue'
import {
    routesProperties
} from "../../backend/properties";
import {
    saveSolidInfo
} from "@/backend/handler/SessionHandler";
import IGCache from "@/backend/cache/IGCache";

@Component({
    components: {
        Bubble1,
        Logo,
        Bubble2,
        OutputPage,
    },
})
export default class LoadPage extends Vue {

    loading = true;
    photos = true;

    mounted() {
        executeMain(window.location.href).then(() => {
            if (IGCache.getInstance().mediaList.length >= 8)
                this.photos = true;
            else
                this.photos = false;
            saveSolidInfo().then(() => {
                this.loading = false;
            });
        });
    }

    goHome() {
        window.location.href = routesProperties.welcome;
    }
    goBack() {
        window.location.href = routesProperties.home;
    }
}
