<template>
<div id="container">
    <b-container fluid id="headerFaq">
        <b-row>
            <b-col align-self="center" lg="1" md="1"></b-col>
            <b-col align-self="center" lg="1" md="1">
                <h2 id="faqtitle"><strong>FAQs</strong></h2>
            </b-col>
        </b-row>
        <b-row>
            <b-col align-self="center" lg="1" md="1"></b-col>

            <b-col align-self="center" lg="10" md="10">
                <b-form-input type="text" v-model="searchQuery" id="searchBar" size="lg" placeholder="   Hi!, how can we help?"></b-form-input>
            </b-col>
            <b-col align-self="center" lg="1" md="1"></b-col>
        </b-row>
    </b-container>
    <b-container fluid id="card">
        <b-row id="row">
            <b-col align-self="center" lg="1" md="1"></b-col>
            <b-col align-self="center" lg="10" md="10">
                <h2>
                    <strong>Hey!, don't miss out on anything you need to know about
                        KALA</strong>
                </h2>
            </b-col>
        </b-row>
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" pills align="center" aria-controls="questionsRow"></b-pagination>
        <b-list-group id="questionsRow" :per-page="perPage" :items="filteredFAQ" :current-page="currentPage">
            <b-list-group-item id="cardItem" v-for="question in itemsForList" v-bind:key="question.id">
                <div>
                    <h6>
                        <strong>{{ question.title }}</strong>
                    </h6>
                    <h5>
                        <strong>{{ question.subtitle }}</strong>
                    </h5>
                </div>
                <p>{{ question.msg }}</p>
            </b-list-group-item>
        </b-list-group>
    </b-container>
</div>
</template>

<script lang="js">
export default {
    name: "FAQ",
    components: {},
    data() {
        return {
            currentPage: 1,
            perPage: 3,
            searchQuery: "",
            questions: [{
                    title: "About Solid",
                    subtitle: "What is Solid?",
                    msg: "Solid is a decentralized systems which lets you bring your data together into a data store called a Pod. It is like a personal Web server for your data."
                },
                {
                    title: "About Solid",
                    subtitle: "What is a Pod?",
                    msg: "A Pod is where data is stored on the Web with Solid. You may store your data in one Pod or several Pods, and applications read and write data into the Pod depending on the authorisations granted by you."
                },
                {
                    title: "About your data",
                    subtitle: "What exactly is personal data?",
                    msg: "Personal data is personal. It refers to data sets describing a person ranging from physical attributes to their preferences and behaviour. Personal data is a legal concept, not a technical concept, and it is not utilised in all jurisdictions."
                },
                {
                    title: "About KALA",
                    subtitle: "What KALA does?",
                    msg: "KALA allows you to go further on your Instagram account, giving you the opportunity to learn more about yourself and understand your behaviour on the social media. You will get the frecuency you post in your feed, you will discover your favourite hashtags and you will get a beautiful composition with your best pictures combined with your feed dominant colors. KALA also provides you with the option of exporting all your Instagram pictures into your personal Pod in your profile area."
                },
                {
                    title: "About Solid",
                    subtitle: "What are the benefits of Solid?",
                    msg: "· You control the data in your Pod" +
                        "· It is all stored and accessed using standard, open, and interoperable data formats and protocols." +
                        "· Any kind of information can be stored in a Solid Pod." +
                        "· You can share slices of your data with the people, organizations, and applications you choose, and you can revoke that access at any time."
                },
                {
                    title: "About KALA",
                    subtitle: "Do I need an Instagram account to use KALA?",
                    msg: "Yes, you need a public Instagram account with at least 8 pictures in your feed"
                },
                {
                    title: "About KALA",
                    subtitle: "Do I need to use Solid in order to use KALA?",
                    msg: "Yes, we want everyone to trust KALA. To achieve that, KALA does not keep any personal data from you but instead is stored in your personal pod so the control is yours."
                },
                {
                    title: "About KALA",
                    subtitle: "Is the Solid account and KALA account the same thing?",
                    msg: "No, there is no KALA account. Everything you obtain with KALA is stored in your Pod as KALA does not keep any data."
                },
                {
                    title: "About Solid",
                    subtitle: "Solid does not convince me. Can I delete my account?",
                    msg: "Yes, you can delete your account at any time in your profile area. But be aware, all the data stored in your Pod will be permanently gone."
                },

            ],

        };
    },
    computed: {
        filteredFAQ() {
            return this.questions.filter((question) => {
                return (question.title.toLowerCase().match(this.searchQuery.toLowerCase()) || question.subtitle.toLowerCase().match(this.searchQuery.toLowerCase()))
            })
        },
        rows() {
            return this.filteredFAQ.length
        },
        itemsForList() {
            return this.filteredFAQ.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage,
            );
        }
    }
};
</script>
<style scoped>
#container {
    background-color: #cae5f6;
    margin-top: 10%;
    padding-bottom: 10%;
}
#card { width: 70vw;}
#row { text-align: left;}
#headerFaq { width: 65vw;}
#searchBar {
    border-radius: 50px;
    z-index: 1;
    height: 60px;
    position: absolute;  
    transform: translate(0%, 50%);
}
#faqtitle {
    position: absolute;
    transform: translate(0%, -150%);
}
#cardItem {
    width: 80%;
    margin: 0% 0% 2% 10%;
    background-color: transparent;
    border: 1px solid #9aa7ee;
    border-radius: 50px;
    padding: 3%;
    text-align: left;
}
.pagination :first-child { color: black;}
p {font-size: 2vh;}
h6 {
    color: #605de3;
    line-height: 25px;
}
h2 {
    line-height: 50px;
    margin: 15% 4% 5% 4%;
}
</style>
