import Color from "../model/instagram/Color";
import IGUser from "../model/instagram/IGUser";
import Media from "../model/instagram/Media";

class IGCache {

  private static instance: IGCache;

  public user!: IGUser;
  public hashtags: string[];
  public mediaList: Media[];
  public dominantPalette: Color[];
  public mood: string;

  private constructor() {
    this.hashtags = [];
    this.mediaList = [];
    this.dominantPalette = [];
    this.mood = "";
  }

  public static getInstance(): IGCache {
    if (!IGCache.instance) {
      IGCache.instance = new IGCache();
    }
    return IGCache.instance;
  }

  public setUserToCache(user: IGUser) {
    this.user = user;
  }

  public addMediaToCache(media: Media) {
    if (!this.mediaList.find((obj) => media.getMediaID() === obj.getMediaID())) {
      this.mediaList.push(media);
    }
  }
}

export default IGCache;