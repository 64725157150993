








import {
    Component,
    Vue
} from "vue-property-decorator";
import Header from "../components/headers/Header.vue";
import Footer from "../components/Footer.vue";
import FAQList from "../components/faq/FAQList.vue";

@Component({
    components: {
        Header,
        FAQList,
        Footer,
    },
})
export default class FAQPage extends Vue {}
