
import SolidUser from "../model/solid/SolidUser";

class SolidCache {

    private static instance: SolidCache;

    private webId!: string;
    private user!: SolidUser;
    private lastPalette: undefined | string; // Change for LastPalette Functionality

    private constructor() { }

    public static getInstance(): SolidCache {
        if (!SolidCache.instance) {
            SolidCache.instance = new SolidCache();
        }
        return SolidCache.instance;
    }

    public setUserToCache(user: SolidUser) {
        this.user = user;
    }

    public setLastPalette(lp: string) {
        this.lastPalette = lp;
    }

    public setWebId(webid: string) {
        this.webId = webid;
    }

    public getWebId() {
        if (this.webId) {
            return this.webId;
        }
    }

    public getUser() {
        return this.user;
    }

    public getLastPalette() {
        return this.lastPalette;
    }

}

export default SolidCache;