<template>
  <div id="divColorPalette">
    <h3 id="title">Finally, your color palette is amazing!</h3>
    <div ref="myDiv">
      <h3>
        Your mood is <strong>{{ mood }}</strong>
      </h3>
      <div id="chart-area"></div>
      <b-container fluid>
        <b-row align-h="center" class="rowPalette">
          <b-col class="colPalette" lg="2" md="2" sm="2" cols="3">
            <b-img
              blank
              fluid
              fluid-grow
              thumbnail
              class="imgPalette"
              :blank-color="colors[0]"
              alt="Named color image (red)"
            ></b-img>
          </b-col>
          <b-col class="colPalette" lg="2" md="2" sm="2" cols="3">
            <b-img
              class="imgPalette"
              thumbnail
              fluid
              fluid-grow
              :src="items[0].mediaUrl"
              alt="Image 2"
            ></b-img>
          </b-col>
          <b-col class="colPalette" lg="2" md="2" sm="2" cols="3">
            <b-img
              class="imgPalette"
              thumbnail
              fluid
              fluid-grow
              :src="items[1].mediaUrl"
              alt="Image 3"
            ></b-img>
          </b-col>
        </b-row>
        <b-row align-h="center" class="rowPalette">
          <b-col class="colPalette" lg="2" md="2" sm="2" cols="3">
            <b-img
              class="imgPalette"
              thumbnail
              fluid
              fluid-grow
              :src="items[2].mediaUrl"
              alt="Image 2"
            ></b-img>
          </b-col>
          <b-col class="colPalette" lg="2" md="2" sm="2" cols="3">
            <b-img
              class="imgPalette"
              thumbnail
              fluid
              fluid-grow
              :src="items[3].mediaUrl"
              alt="Image 2"
            ></b-img>
          </b-col>
          <b-col class="colPalette" lg="2" md="2" sm="2" cols="3">
            <b-img
              blank
              fluid
              fluid-grow
              thumbnail
              class="imgPalette"
              :blank-color="colors[1]"
              alt="Named color image (red)"
            ></b-img>
          </b-col>
        </b-row>
        <b-row align-h="center" class="rowPalette">
          <b-col class="colPalette" lg="2" md="2" sm="2" cols="3">
            <b-img
              blank
              fluid
              fluid-grow
              thumbnail
              class="imgPalette"
              :blank-color="colors[2]"
              alt="Named color image (red)"
            ></b-img>
          </b-col>
          <b-col class="colPalette" lg="2" md="2" sm="2" cols="3">
            <b-img
              class="imgPalette"
              thumbnail
              fluid
              center
              fluid-grow
              :src="items[4].mediaUrl"
              alt="Image 2"
            ></b-img>
          </b-col>
          <b-col class="colPalette" lg="2" md="2" sm="2" cols="3">
            <b-img
              class="imgPalette"
              thumbnail
              fluid
              center
              fluid-grow
              :src="items[5].mediaUrl"
              alt="Image 3"
            ></b-img>
          </b-col>
        </b-row>
        <b-row align-h="center" class="rowPalette">
          <b-col class="colPalette" lg="2" md="2" sm="2" cols="3">
            <b-img
              class="imgPalette"
              thumbnail
              fluid
              center
              fluid-grow
              :src="items[6].mediaUrl"
              alt="Image 2"
            ></b-img>
          </b-col>
          <b-col class="colPalette" lg="2" md="2" sm="2" cols="3">
            <b-img
              class="imgPalette"
              thumbnail
              fluid
              center
              fluid-grow
              :src="items[7].mediaUrl"
              alt="Image 3"
            ></b-img>
          </b-col>
          <b-col class="colPalette" lg="2" md="2" sm="2" cols="3">
            <b-img
              blank
              fluid
              fluid-grow
              thumbnail
              class="imgPalette"
              :blank-color="colors[3]"
              alt="Named color image (red)"
            ></b-img>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-button
      @click="download"
      class="b-button"
      id="download"
      pill
      variant="outline-secondary"
    >
      Download
    </b-button>
  </div>
</template>

<script lang="js">
import * as d3 from "d3";

import {
    Component,
    Vue
} from "vue-property-decorator";
import {
    saveSolidInfo
} from "@/backend/handler/SessionHandler";
import SolidCache from "@/backend/cache/SolidCache";
import IGCache from "@/backend/cache/IGCache";
import html2canvas from "html2canvas";
import {
    storePhoto
} from "@/backend/handler/PodHandler";

export default {
    name: "ColorPalette",
    props: ['items', 'colors', 'mood'],
    methods: {
        download() {
            saveSolidInfo().then(() => {

                const solidcache = SolidCache.getInstance();
                const igcache = IGCache.getInstance();

                const height=window.innerHeight;
                console.log(height)
                document.getElementById("chart-area").style.visibility = "hidden";
                html2canvas(this.$refs.myDiv, {
                    logging: true,
                    useCORS: true,
                    allowTaint: true,
                    height:height,
                    y: height/6,
                    scrollY: -height*2
                }).then(function (canvas) {
                    document.getElementById("chart-area").style.visibility = "visible";
                    const link = document.createElement("a");
                    link.setAttribute("id", "enlace");
                    document.body.appendChild(link);
                    link.download = `K A L A.png`;
                    link.href = canvas.toDataURL("image/png", 1);
                    storePhoto(link.href, solidcache.getWebId().split("profile")[0], igcache.user.getUsername());
                    link.target = "_blank";
                    link.click();
                    document.body.removeChild(link);
                });
            })
        }
    },

    mounted() {

        const data = [25, 20, 10, 12, 15];
        const colors = this.colors;
        console.log(this.colors)
        const svg = d3
            .select("#chart-area")
            .append("svg")
            .attr("width", "50%")
            .attr("height", "100%")
            .attr("viewBox", `0 0 100 20`);
        // Define the gradient
        const gradient = svg
            .append("defs")
            .selectAll("radialGradient")
            .data(colors)
            .enter()
            .append("radialGradient")
            .attr("gradientUnits", "objectBoundingBox")
            .attr("cx", 0)
            .attr("cy", 0)
            .attr("r", "100%")
            .attr("id", function (d, i) {
                return "grad" + i;
            });

        // Define the gradient colors
        gradient
            .append("svg:stop")
            .attr("offset", "0%")
            .attr("stop-color", "white")
            .attr("stop-opacity", 1);

        gradient
            .append("svg:stop")
            .attr("offset", "100%")
            .attr("stop-color", function (d, i) {
                return colors[i];
            })
            .attr("stop-opacity", 1);

        const circles = svg.selectAll("circle").data(data);

        circles
            .enter()
            .append("circle")
            .attr("r", "5px")
            .attr("cx", (d, i) => i * 18 + 15)
            .attr("cy", "50%")
            .style("fill", function (d, i) {
                return "url(#grad" + i + ")";
            });
    },
};
</script>

<style scoped>
#download {
  margin: 3%;
}

#divColorPalette {
  background-color: transparent;
  overflow: hidden;
  z-index: 999999999999999999999999;
}

#title {
  margin-top: 25%;
}

h3 {
  font-weight: 600;
  letter-spacing: 2px;
  z-index: 999999999999999999999999;
}

@media (min-width: 200px) and (max-width: 400px) {
  .imgPalette {
    object-fit: cover;
    width: 80px !important;
    height: 80px !important;
  }

  #containerPalette {
    width: 100% !important;
    margin: 0px !important;
  }

  .colPalette {
    padding: 0px !important;
  }
}

.imgPalette {
  object-fit: cover;
  min-height: 100%;
  min-width: 100%;
  max-width: 40vh !important;
}

.colPalette {
  padding: 0px 3px 0px 3px !important;
}
</style>
