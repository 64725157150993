<template>
<b-container fluid id="personal">
    <div id="principal" v-if="!showModal">
        <div id=changepass>
            <b-row>
                <b-col lg="12" md="12" sm="12" cols="12">
                    <h1 id="changeTitle">Do you need to change <br />your password?</h1>
                </b-col>
                <b-button class="b-button" id="password" @click="password" pill variant="outline-secondary">Yes, I do!</b-button>
            </b-row>
        </div>
        <div id="imagine">
            <Bubble2 id="bubble3"></Bubble2>
            <Bubble3 id="bubble4"></Bubble3>
            <h1>
                <strong>Delete your account?</strong>
            </h1>
            <b-button class="b-button" id="delete" @click="popup" pill variant="outline-secondary">Yes, I want</b-button>
        </div>
    </div>
    <div id="modal" v-if="showModal">
        <Bubble2 id="bubble3"></Bubble2>
        <Bubble3 id="bubble4"></Bubble3>
        <h1 id="titledelete">
            <strong>Are your sure you want to delete your account?, remember
                that this action is irreversible and we are transparent
            </strong>
        </h1>
        <b-row>
            <b-col lg="6" md="6" sm="6" cols="6">
                <b-button class="b-button" id="noDelete" @click="noDelete" pill variant="outline-secondary">No</b-button>
            </b-col>
            <b-col lg="6" md="6" sm="6" cols="6">
                <b-button class="b-button" id="deleteConfirm" @click="deleteConfirm" pill variant="outline-secondary">Yes</b-button>
            </b-col>
        </b-row>
    </div>
</b-container>
</template>

<script lang="js">
import Bubble2 from "../../assets/bubble2.vue";
import Bubble3 from "../../assets/bubble3.vue";
export default {
    name: "ConfigurationTab",
    components: {
        Bubble2,
        Bubble3
    },
    data() {
        return {
            showModal: false
        }
    },
    methods: {
        popup: function () {
            this.showModal = true;
        },
        deleteConfirm: function () {
            window.open("https://inrupt.net/account/delete");
        },
        noDelete: function () {
            this.showModal = false;
        },
        password: function () {
            window.open("https://inrupt.net/account/password/reset");
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
#personal {
    margin: 5%;
}

#bubble3 {
    transform: translate(-60vw, -10vw);
}

#bubble4 {
    transform: translate(40vw, -5vw)rotate(30deg);
}

#changepass {
    background-image: url("../../assets/changepass.svg");
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 35vh;
    min-width: 70vw;
    margin: 10% 0% 0% -20%;

}

#changeTitle {
    margin: -5% 0% 0% 3.5%;
    letter-spacing: 2px;
    line-height: 140%;

}

#imagine h1 {
    letter-spacing: 1px;
    margin: 0% 0% 0% 1%;
}

h1 {
    font-weight: 600;

}

#titledelete {
    margin: 0% 0% 10% -5%;
    line-height: 150%;

}

#profilePic {
    height: 10vw;
    width: 10vw;
    object-fit: cover;
    border: 10px solid rgba(255, 255, 255, 0.6);
}

#password,
#noDelete {
    background-color: #3c2fdd;
}

#password {
    margin-left: 41%;
}

#modal {
    padding: 10%;
    margin: 2% 0% 15% 0%;
}

#imagine {
    background: #dfddf1;
    padding: 10% 10% 25% 10%;
    width: 110vw;
    margin: 0% 0% 10% -5%;
    position: relative;
    left: -50%;
}

#noDelete,
#deleteConfirm {
    width: 50%;
    margin-left: -15% !important;
}

#delete,
#deleteConfirm {
    background-color: #d02fdd;
    margin-left: 0.5%;
}

.b-button {
    margin: 2% auto 0% auto;
    color: white;
    width: 20%;
    font-size: 2vh;
    border: none;
}
</style>
