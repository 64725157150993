<template>
  <div>
    <Header class="fixed"></Header>
    <div id="app">
        <div class="sections-menu">
            <span class="menu-point" v-bind:class="{ active: activeSection == index }" v-on:click="scrollToSection(index)" v-for="(offset, index) in offsets" v-bind:key="index"></span>
        </div>
        <section class="fullpage">
            <DensityGraph v-bind:items="items"></DensityGraph>
        </section>
        <section class="fullpage">
            <WordCloud v-bind:hashtags="hashtags"></WordCloud>
        </section>
        <section class="fullpage">
            <ColorPalette v-bind:items="items" v-bind:mood="mood" v-bind:colors="colors"></ColorPalette>
        </section>
    </div>
</div>
</template>

<script lang="js">

import Header from "../components/headers/HeaderLogged.vue";
import ColorPalette from "../components/outputs/ColorPalette.vue";
import WordCloud from "../components/outputs/WordCloud.vue";
import DensityGraph from "../components/outputs/DensityGraph.vue";
import {
    Component,
    Vue
} from "vue-property-decorator";
import IGCache from "@/backend/cache/IGCache";

@Component({
    components: {
        ColorPalette,
        Header,
        WordCloud,
        DensityGraph
    },
})
export default class OutputPage extends Vue {
    data() {
        return {
            inMove: false,
            activeSection: 0,
            mood: "",
            offsets: [],
            touchStartY: 0,
            items: [],
            colors: [],
            hashtags: [],

        };
    }

    beforeMount() {
        const x = (photos) => photos.filter((v, i) => photos.indexOf(v) === i);
        IGCache.getInstance().dominantPalette.forEach((c) => {
            this.colors.push(c.getHexColor()); //push colors into palette
            this.items.push(IGCache.getInstance().mediaList.find((a) => a.id === c.mediaId));
            this.items = x(this.items); //filter to avoid repeat photos
        });

        while (this.items.length < 8) {
            const newMedia =
                IGCache.getInstance().mediaList[Math.floor(Math.random() * IGCache.getInstance().mediaList.length)];

            if (!this.items.find((a) => a.id === newMedia.id)) {
                this.items.push(newMedia);
            }
        }

        this.hashtags = IGCache.getInstance().hashtags;
        this.mood = IGCache.getInstance().mood;

    }

    calculateSectionOffsets() {
        const sections = document.getElementsByTagName('section');
        const length = sections.length;
        for (let i = 0; i < length; i++) {
            const sectionOffset = sections[i].offsetTop;
            this.offsets.push(sectionOffset);
        }
    }

    handleMouseWheel(e) {
        if (e.wheelDelta < 30 && !this.inMove) {
            this.moveUp();
        } else if (e.wheelDelta > 30 && !this.inMove) {
            this.moveDown();
        }
        e.preventDefault();
        return false;
    }

    handleMouseWheelDOM(e) {
        if (e.detail > 0 && !this.inMove) {
            this.moveUp();
        } else if (e.detail < 0 && !this.inMove) {
            this.moveDown();
        }
        return false;
    }

    moveDown() {
        this.inMove = true;
        this.activeSection--;
        if (this.activeSection < 0) this.activeSection = this.offsets.length - 1;
        this.scrollToSection(this.activeSection, true);
    }

    moveUp() {
        this.inMove = true;
        this.activeSection++;
        if (this.activeSection > this.offsets.length - 1) this.activeSection = 0;
        this.scrollToSection(this.activeSection, true);
    }

    scrollToSection(id, force = false) {
        if (this.inMove && !force) return false;
        this.activeSection = id;
        this.inMove = true;
        document.getElementsByTagName('section')[id].scrollIntoView();
        setTimeout(() => {
            this.inMove = false;
        }, 400);

    }

    touchStart(e) {
        e.preventDefault();
        this.touchStartY = e.touches[0].clientY;
    }

    touchMove(e) {
        if (this.inMove) return false;
        e.preventDefault();
        const currentY = e.touches[0].clientY;
        if (this.touchStartY < currentY) {
            this.moveDown();
        } else {
            this.moveUp();
        }
        this.touchStartY = 0;
        return false;
    }

    mounted() {
        this.calculateSectionOffsets();
    }
    created() {
        window.addEventListener('DOMMouseScroll', this.handleMouseWheelDOM); // Mozilla Firefox
        window.addEventListener('mousewheel', this.handleMouseWheel, {
            passive: false
        }); // Other browsers
        window.addEventListener('touchstart', this.touchStart, {
            passive: false
        }); // mobile devices
        window.addEventListener('touchmove', this.touchMove, {
            passive: false
        }); // mobile devices
    }
    destroyed() {
        window.removeEventListener('mousewheel', this.handleMouseWheel, {
            passive: false
        }); // Other browsers
        window.removeEventListener('DOMMouseScroll', this.handleMouseWheelDOM); // Mozilla Firefox
        window.removeEventListener('touchstart', this.touchStart); // mobile devices
        window.removeEventListener('touchmove', this.touchMove); // mobile devices
    }
}
</script>

<style scoped>
h2 {
    position: fixed;
}

.fixed {
    position: fixed;
    height: 10px;
    top: -3%;
}

.fullpage {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

h1 {
    font-size: 6em;
    margin: 0;
    text-align: center;
    padding: 0 1rem;
}

p {
    font-size: 1em;
}

.fullpage a {
    text-decoration: none;
    font-weight: 600;
    background: rgba(255, 255, 255, 0.3);
    color: #fff;
    margin-left: 5px;
}

.sections-menu .menu-point {
    width: min(1vw, 1.5vh);
    height: min(1vw, 1.5vh);
    background-color: darkgrey;
    display: block;
    margin: 1rem 0.5rem;
    opacity: 0.6;
    transition: 0.4s ease all;
    cursor: pointer;
    border-radius: 10px;
}

.sections-menu .menu-point.active {
    opacity: 1;
    transform: scale(1.5);
}

.sections-menu {
    position: fixed;
    right: 4rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999999999;
}

@media screen and (max-width: 1200px) {
    h1 {
        font-size: 2.5em;
    }
}
</style>
