import ColorPalette from "../model/instagram/ColorPalette";
import IGUser from "../model/instagram/IGUser";
import IGCache from "../cache/IGCache";

const KALA_FOLDER = 'kala/';
const PALETTES_FOLDER = 'palette/';
const COLOR_PALETTE_FOLDER = 'image/';
const INSTAGRAM_FOLDER = 'instagram/';

const auth = require('solid-auth-client');
const FC = require('solid-file-client');
const fc = new FC(auth);

// ----------------------------------
// ----- STORE IN POD FUNCTIONS -----
// ----------------------------------
export function storePalette(cp: ColorPalette, webid: string | undefined) {

    const fileName = `${cp.getName}@${cp.getID}.jsonld`;
    const url = webid + KALA_FOLDER + PALETTES_FOLDER + fileName;
    storeFile(url, cp.toJsonLD());
    storePaletteImg(cp, webid);
}

function storePaletteImg(cp: ColorPalette, webid: string | undefined) {

    const fileName = `${cp.getName}@${cp.getID}.png`;
    const url = webid + KALA_FOLDER + PALETTES_FOLDER + COLOR_PALETTE_FOLDER + fileName;
    storeFile(url, cp.getContent());
}

export function exportIGData(userId: string, userName: string, webid: string | undefined) {

    const url = webid + KALA_FOLDER + INSTAGRAM_FOLDER + `${userName}@${userId}.jsonld`;
    const user: IGUser = IGCache.getInstance().user;
    const mediaJSON = user.toJsonTemplateExport();
    storeFile(url, mediaJSON);
}

export function storePhoto(palette: any, webid: string | undefined, userName: string) {
    // const fileName = `${Date.now()}@${userName}.txt`
    const fileName = `${userName}.txt`
    console.log(fileName);
    const url = webid + KALA_FOLDER + PALETTES_FOLDER + fileName;
    console.log(url);
    storeFile(url, palette);
    console.log(fileName);
}


function storeFile(url: string, data: any) {
    fc.createFile(url, data);
}


// ----------------------------------
// ----- GET FROM POD FUNCTIONS -----
// ----------------------------------
export async function findAllColorPalettes(webid: string | undefined) {

    const url = webid + KALA_FOLDER + PALETTES_FOLDER;
    console.log(url)
    const colorPalettes = [];

    if (await fc.itemExists(url)) {
        console.log(true)
        try {
            const contents = await fc.readFolder(url);
            const files = contents.files;

            for (let i = 0; i < files.length; i++) {
                const fileContent = await fc.readFile(files[i].url);
                colorPalettes.push(fileContent);
            }

        } catch (er) {
            throw new Error("An error happenned when retrieving color palette from Pod.");
        }
        return colorPalettes[0];
    } else {
        return null;
    }

    
}

