












import { Component } from "vue-property-decorator";
import Vue from "vue";
import Header from "../components/headers/Header.vue";
import Footer from "../components/Footer.vue";
import ProfileTabs from "../components/profile/ProfileTabs.vue";
import Bubble2 from "../assets/bubble2.vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

Vue.use(VueToast, {
  position: "top",
  duration: 10000,
});

@Component({
  components: {
    Header,
    ProfileTabs,
    Bubble2,
    Footer,
  },
})
export default class ProfilePage extends Vue {
  mounted() {
    const param = this.$route.query.state;
    if (param) {
      switch (param) {
        case "export-success":
          Vue.$toast.success("Your IG data was exported successfully!");
          break;
        default:
          Vue.$toast.error("An error happened while exporting your IG data.");
          break;
      }
    }
  }
}
