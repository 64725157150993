import Color from "./Color";

class Media {


  private id: number;
  private caption: string;
  private mediaUrl: string;
  private timestamp: string;
  private mediaType: string;
  private colors: Color[];
  private extension: string;

  /**
   *
   * @param {String} id ID.
   * @param {String} caption Caption text. Not returnable for Media in albums.
   * @param {String} mediaType The Media's type. Can be IMAGE, VIDEO, or CAROUSEL_ALBUM.
   * @param {String} mediaUrl The Media's URL.
   * @param {String} timestamp The Media's publish date in ISO 8601 format.
   */
  constructor(id: number, caption: string, mediaType: string, mediaUrl: string, timestamp: string, extension: string, colors: Color[] = []) {
    this.id = id;
    this.caption = caption;
    this.mediaType = mediaType;
    this.mediaUrl = mediaUrl;
    this.timestamp = timestamp;

    this.colors = colors;
    this.extension = extension;
  }

  getMediaID() {
    return this.id;
  }

  getCaption() {
    return this.caption;
  }

  getMediaType() {
    return this.mediaType;
  }

  getMediaURL() {
    return this.mediaUrl;
  }

  getTimestamp() {
    return this.timestamp;
  }

  setHexColors(colorList: Color[]) {
    this.colors = colorList;
  }

  getHexColors() {
    return this.colors;
  }

  getExtension() {
    return this.extension;
  }

  toJson() {
    return {
      "id": this.id,
      "url": this.mediaUrl
    };
  }
}

export default Media;
