
























import InstagramLogo from "../../assets/kala_inst_icon.vue";
import Bubble from "../../assets/bubble1.vue";
import Bubble2 from "../../assets/bubble2.vue";
import Bubble4 from "../../assets/bubble4.vue";
import Bubble3 from "../../assets/bubble3.vue";

export default {
    name: "PrivacyInfo",
    components: {
        InstagramLogo,
        Bubble,
        Bubble2,
        Bubble3,
        Bubble4
    },
};
