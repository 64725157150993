<template>
<b-container fluid id="container">
    <b-row align-h="between" align-v="center">
        <b-col lg="8" md="8" sm="8" cols="12">
            <div @click="goHome">
                <Logo id="logo"></Logo>
            </div>
        </b-col>
        <b-col align-self="center" lg="4" md="4">
            <div>
                <Avatar></Avatar>
                <div id="profileMenu">
                    <b-dropdown variant="primary" text="Profile" class="myProfile">
                        <template #button-content>
                            Profile <Arrow id="arrow"></Arrow><span class="sr-only">Search</span>
                        </template>
                        <div class="mydrop tip left">
                            <b-dropdown-item class="item" @click="account">Account</b-dropdown-item>
                            <b-dropdown-item class="item" @click="logout">Log out</b-dropdown-item>
                        </div>
                    </b-dropdown>
                </div>
            </div>
        </b-col>
    </b-row>
</b-container>
</template>

<script lang="js">
import {initLogout} from "@/backend/handler/SessionHandler";
import Logo from "../../assets/kala_logo.vue";
import Avatar from "../../assets/avatar.vue";
import Arrow from "../../assets/arrow_down.vue";
import { routesProperties} from "../../../backend/properties.ts";

export default {
    name: "HeaderLogged",
    components: {
        Logo,
        Avatar,
        Arrow,
    },
    methods: {
        logout() {
            initLogout();
        },
        account() {
            window.location.href = routesProperties.profile;
        },
        goHome() {
            window.location.href = routesProperties.welcome;
        },
    },
};
</script>


<style scoped>
#container {
    background-color: transparent;
}
#profileMenu {
    display: inline-flex;
    margin-left: 10px;
}
#logo {
    min-width: 15%;
    width: 15%;
    margin:10% 0% 10% 10%;
    cursor: pointer;
}
.badge-primary {
    color: #6258e2;
    background-color: #efefef !important;
    border: 1px solid #6258e2;
}
#arrow {
    margin-top: 10%;
    transform: rotate(180deg);
}
.tip {
    background: #e2e1f2;
    margin: 25% 25%;
    padding: 0px;
    position: relative;
}
.tip:after {
    position: relative;
    top: -125px;
    left: 170%;
    border-right: 20px solid transparent;
    border-bottom: 30px solid#e2e1f2;
    border-left: 20px solid transparent;
    content: "";
}
</style><style lang="scss">
@import "../../../assets/custom.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-vue/src/index.scss";

.myProfile li a:hover {
    color: #3c2fdd;
    font-weight: 600;
    background-color: #e2e1f2 !important;
}

.myProfile li a {
    color: #3c2fdd;
    margin:10% 0% 0% -10%;
}

.myProfile ul {
    padding: 0% 100% 0% 0%;
    margin:15% 0% 0% -60%;    
    line-height: 100%;
}

button[id^="__BVID"] {
    font-size: 2vh;
    font-family: "Montserrat";
    color: #3c2fdd;
}

button[id^="__BVID"]:hover  {
    background-color: transparent !important;
    outline: none;
    border: none;
    transition: none;
    color: #3c2fdd;
    font-weight: 600;
}

button[id^="__BVID"]:focus {
    background-color: transparent !important;
    outline: none;
    border: none;
    transition: none;
    color: #3c2fdd;
    font-weight: 600;
}

button[id^="__BVID"]:not(:disabled):not(.disabled) {
    background-color: transparent !important;
    outline: none;
    border: none;
    transition: none;
    color: #3c2fdd;
}

button[id^="__BVID"]::after {
    background-color: transparent !important;
    outline: none;
    border: none;
    transition: none;
    color: #3c2fdd;
}
</style>
